import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

import type { TagEntityType } from "src/services/models/Tag";
import { useDeleteEntityTag } from "src/services/mutations";
import { useEntityTags } from "src/services/queries";
import { useTagsById } from "src/hooks/useTags";

import { ModalContainer, ModalHeader } from "../AddTagModal";
import { ReviewEntityTag } from "../EntityTag";
import { StackStyled } from "../tradespeople/ReviewTradespersonTagsModal";

type ReviewJobTagsModelProps = {
  jobId: string;
  source: TagEntityType;
  onClose: (reason: "close" | "mutate") => void;
};

export const ReviewJobTagsModal = ({ jobId, source, onClose }: ReviewJobTagsModelProps) => {
  const entityTagsQuery = useEntityTags({
    variables: {
      jobId,
      source,
    },
  });

  const deleteEntityTagMutation = useDeleteEntityTag();
  const { refetchTags } = useTagsById();

  const deleteEntityTag = async (entityTagId: string) => {
    try {
      await deleteEntityTagMutation.mutateAsync({ entityTagId });
      refetchTags();
      onClose("mutate");
    } catch (e) {}
  };

  const entityTags = entityTagsQuery.data || [];

  if (entityTagsQuery.isLoading) {
    return (
      <Modal data-testid="add-tags-modal" open={true} onClose={close}>
        <ModalContainer>
          <ModalHeader>
            <Stack
              gap="5px"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              flexWrap="nowrap"
            >
              <Typography noWrap variant="h3">
                Review Tags
              </Typography>
              <IconButton aria-label="close" onClick={() => onClose("close")}>
                <Close />
              </IconButton>
            </Stack>
          </ModalHeader>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </ModalContainer>
      </Modal>
    );
  }

  return (
    <Modal data-testid="add-tags-modal" open={true} onClose={() => onClose("close")}>
      <ModalContainer>
        <ModalHeader>
          <Stack
            gap="5px"
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
          >
            <Typography noWrap variant="h3">
              Review Tags
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose("close")}>
              <Close />
            </IconButton>
          </Stack>
        </ModalHeader>
        <StackStyled data-testid="tags-list" spacing={1}>
          {Object.values(entityTags).map((entityTag) => {
            return (
              <ReviewEntityTag
                key={`entity-tag-id-${entityTag.id}`}
                entityTag={entityTag}
                deleteEntityTag={deleteEntityTag}
                showTradespersonInfo
              />
            );
          })}
        </StackStyled>
      </ModalContainer>
    </Modal>
  );
};
