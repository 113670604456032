import { createSvgIcon } from "@mui/material";

export const SingleDoc = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 5 19.3333 L 15 19.3333 C 16.3807 19.3333 17.5 18.214 17.5 16.8333 V 11 L 17.5 7.9828 C 17.5 7.3717 17.2762 6.7818 16.8709 6.3246 L 15.4974 4.775 L 14.4286 3.5347 C 13.9536 2.9836 13.2622 2.6667 12.5347 2.6667 H 10 H 5 C 3.6193 2.6667 2.5 3.7859 2.5 5.1667 V 16.8333 C 2.5 18.214 3.6193 19.3333 5 19.3333 Z M 13.3333 4.8168 L 14.2424 5.8718 L 15.0947 6.8333 H 13.3333 V 4.8168 Z M 11.6667 4.3333 V 7.6667 C 11.6667 8.1269 12.0398 8.5 12.5 8.5 H 15.8333 V 11 L 15.8333 16.8333 C 15.8333 17.2936 15.4602 17.6667 15 17.6667 L 5 17.6667 C 4.5398 17.6667 4.1667 17.2936 4.1667 16.8333 L 4.1667 5.1667 C 4.1667 4.7064 4.5398 4.3333 5 4.3333 L 10 4.3333 L 11.6667 4.3333 Z"
  />,

  "SingleDoc",
);
