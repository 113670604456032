import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";

import { Search as SearchIcon } from "../../icons/search";

interface SearchBarProps {
  onUpdateSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
}

export const SearchBar = ({ onUpdateSearch: handleSearch, name, value }: SearchBarProps) => {
  return (
    <Box>
      <TextField
        label={`Search ${name}`}
        data-testid={`search-input-${name}`}
        fullWidth
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon color="action" fontSize="small">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={handleSearch}
      />
    </Box>
  );
};
