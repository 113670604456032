import type { ChipProps } from "@mui/material/Chip";
import Chip from "@mui/material/Chip";

interface StyledChipProps extends ChipProps {
  customcolor: string;
  margintop?: number;
}

export const StyledChip = ({ customcolor, margintop, ...props }: StyledChipProps) => (
  <Chip
    {...props}
    sx={{
      borderColor: customcolor,
      color: customcolor,
      paddingRight: 0,
      marginTop: margintop ? margintop : 0,
    }}
  />
);
