import { useMemo } from "react";

import { useAuthStore } from "src/context/authStore";
import { JobJobTypeEnum } from "src/services/models/Job";

export const jobTypes: { value: JobJobTypeEnum; label: string; productId?: string }[] = [
  {
    value: JobJobTypeEnum.BoilerService,
    label: "Boiler Service",
  },
  {
    value: JobJobTypeEnum.BoilerServiceRecall,
    label: "Boiler Service Recall",
  },
  {
    value: JobJobTypeEnum.BoilerServiceLandlord,
    label: "Gas Safety Certificate",
  },
  {
    value: JobJobTypeEnum.BoilerServiceCombo,
    label: "Gas Safety Cert & Boiler Service",
  },
  {
    value: JobJobTypeEnum.BoilerInstallation,
    label: "Boiler Installation",
  },
  {
    value: JobJobTypeEnum.HeatingRepair,
    label: "Heating Repair",
  },
  {
    value: JobJobTypeEnum.HeatingRecall,
    label: "Heating Recall",
  },
  {
    value: JobJobTypeEnum.PlumbingRepair,
    label: "Plumbing Repair",
  },
  {
    value: JobJobTypeEnum.PlumbingRecall,
    label: "Plumbing Recall",
  },
  {
    value: JobJobTypeEnum.HomeEnergyAssessment,
    label: "Home Energy Assessment",
    productId: "871a9db5-d2e4-4d91-b986-75aa2a3097c2",
  },
  {
    value: JobJobTypeEnum.SmartThermostatInstall,
    label: "Smart Thermostat Install",
  },
  {
    value: JobJobTypeEnum.SmartThermostatInstallRecall,
    label: "Smart Thermostat Install Recall",
  },
];

export function useGetJobTypes(isOvoEnergyPartner: boolean) {
  const isAdmin = useAuthStore((state) => state.isAdmin);
  return useMemo(() => getJobTypes(isAdmin, isOvoEnergyPartner), [isAdmin, isOvoEnergyPartner]);
}

function getJobTypes(
  isAdmin: boolean,
  isOvoEnergyPartner: boolean,
): { value: JobJobTypeEnum; label: string; productId?: string }[] {
  let filteredJobTypes = jobTypes;

  if (isAdmin) {
    return filteredJobTypes;
  }

  filteredJobTypes = jobTypes.filter(({ value }) => value !== JobJobTypeEnum.BoilerInstallation);

  if (!isOvoEnergyPartner) {
    filteredJobTypes = filteredJobTypes.filter(
      ({ value }) => value !== JobJobTypeEnum.HomeEnergyAssessment,
    );
  }

  return filteredJobTypes;
}

export const jobTypeLookup = new Map<JobJobTypeEnum, string>(
  jobTypes.map(({ value, label }) => [value, label]),
);
