import Modal from "@mui/material/Modal";
import React from "react";
import { JobTagList } from "./JobTagList";
import { styled } from "@mui/material/styles";

interface JobTagModalProps {
  jobId: string;
  tradespersonId: string | undefined;
  isOpen: boolean;
  /**
   * Close handler with a reason for closing.
   */
  onClose: (reason: "close" | "mutate") => void;
}

export function JobTagModal(props: JobTagModalProps) {
  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <ModalContainer>
          <JobTagList
            defaultAddModeOnZeroTags
            jobId={props.jobId}
            tradespersonId={props.tradespersonId}
          />
        </ModalContainer>
      </Modal>
    </>
  );
}

const ModalContainer = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40%;
  max-width: 48em;
  min-height: 200px;

  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-flow: column;
  align-items: stretch;

  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  background-color: white;
  border-radius: 20px;
`;
