import { AddButton } from "./AddButton";
import Add from "@mui/icons-material/Add";
import { Menu, MenuItem } from "@mui/material";
import MuiLink from "@mui/material/Link";
import React, { type ChangeEvent, type MouseEvent, useState } from "react";
import Link from "next/link";

interface AddJobButtonProps {
  initiateFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  canBulkUpload: boolean;
}

export const AddJobButton = (props: AddJobButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.canBulkUpload ? (
        <>
          <AddButton
            id="add-job-button"
            aria-controls={open ? "add-job-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Add />
          </AddButton>
          <Menu
            id="add-job-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "add-job-button",
            }}
          >
            <MenuItem component={MuiLink} href="/new-job">
              Create new job
            </MenuItem>
            <MenuItem color="primary" component="label">
              <input
                hidden
                accept="text/csv"
                type="file"
                onChange={props.initiateFileUpload}
                disabled={props.isUploading}
              />
              Bulk import jobs (.csv)
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <Link href="/new-job" passHref legacyBehavior>
            <AddButton aria-label="Create Job">
              <Add />
            </AddButton>
          </Link>
        </>
      )}
    </>
  );
};
