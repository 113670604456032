import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useId, useReducer } from "react";

import { shouldForwardProp } from "src/utils/shouldForwardProp";

export type AddOrRemovePeopleEventType = "add" | "remove";

export interface JobAssignmentProps {
  jobRefId: string;
  people: string[];
  addOrRemovePeople: (
    jobRefId: string,
    eventType: AddOrRemovePeopleEventType,
    initials: string,
  ) => Promise<string[]>;
}

export function JobAssignment({ people, jobRefId, addOrRemovePeople }: JobAssignmentProps) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const anchorId = useId();

  const openAssignmentModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    dispatch({ type: "open", anchorEl: e.currentTarget });
  };

  const clearPeopleFromJob = async () => {
    if (people.length === 0) {
      dispatch({ type: "close" });
    }
    try {
      await Promise.allSettled(
        people.map(async (initials) => {
          await addOrRemovePeople(jobRefId, "remove", initials);
        }),
      );
    } catch (e) {
      alert("Error while removing people from job");
    }

    dispatch({ type: "exitEdit" });
  };

  const deletePeople = async () => {
    if (state.mode === "edit") {
      try {
        await addOrRemovePeople(jobRefId, "remove", state.original);
      } catch (e) {
        alert("Error while removing people from job");
      }
      dispatch({ type: "exitEdit" });
    }
  };

  const createPeople = async () => {
    try {
      if (state.mode === "create") {
        const initials = state.value.trim();

        if (initials.length > 0 && !people.includes(initials)) {
          await addOrRemovePeople(jobRefId, "add", state.value);
        }
      }

      dispatch({ type: "exitEdit" });
    } catch (e) {
      alert("Error while adding new people to job");
    }
  };

  const open = state.mode !== "closed";

  const mode = state.mode;

  return (
    <>
      <BoxStyled onClick={openAssignmentModal} id={anchorId}>
        {people.length ? (
          <AvatarStyled
            data-testid="first-people-avatar"
            $backgroundColor={getInitialsColorFromName(people[0])}
          >
            <Box sx={{ fontSize: "11px" }}>{people[0]}</Box>
          </AvatarStyled>
        ) : (
          <AvatarStyled
            data-testid="add-new-people-btn"
            $backgroundColor={"transparent"}
            $borderColor={grey["500"]}
          >
            <Box sx={{ fontSize: "11px", color: "transparent" }}>+</Box>
          </AvatarStyled>
        )}
      </BoxStyled>

      <Popover
        transitionDuration={0}
        data-testid="add-new-people-popover"
        id={anchorId}
        anchorEl={state.mode !== "closed" ? state.anchorEl : null}
        open={open}
        onClick={(e) => {
          e.preventDefault();
        }}
        onClose={() => dispatch({ type: "close" })}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            createPeople();
          }}
        >
          {mode === "create" && (
            <PopOverBoxStyled>
              <TextField
                type="text"
                variant="outlined"
                size="small"
                placeholder="Enter initials"
                sx={{ maxWidth: "100px", marginRight: "10px" }}
                color={people.includes(state.value) ? "error" : "warning"}
                value={state.value}
                onChange={(e) =>
                  dispatch({ type: "updateValue", value: e.target.value.toUpperCase() })
                }
              />

              <AvatarStyled
                onClick={() => {
                  createPeople();
                }}
                $backgroundColor={"transparent"}
                $borderColor={green["500"]}
                $marginRight={10}
                sx={{ opacity: people.includes(state.value) ? ".5" : "1" }}
              >
                <CheckIcon sx={{ fontSize: "12px", color: green["500"] }} />
              </AvatarStyled>

              <AvatarStyled
                onClick={() => {
                  dispatch({ type: "exitEdit" });
                }}
                $backgroundColor={"transparent"}
                $borderColor={red["500"]}
              >
                <CloseIcon sx={{ fontSize: "12px", color: red["500"] }} />
              </AvatarStyled>
            </PopOverBoxStyled>
          )}

          {mode === "edit" && (
            <PopOverBoxStyled data-testid="people-edit-mode">
              <AvatarStyled
                $backgroundColor={getInitialsColorFromName(state.original)}
                $marginRight={10}
              >
                <Box sx={{ fontSize: "11px" }}>{state.original}</Box>
              </AvatarStyled>

              <AvatarStyled
                onClick={() => dispatch({ type: "exitEdit" })}
                $backgroundColor={"transparent"}
                $borderColor={grey["500"]}
                $marginRight={10}
              >
                <ArrowBack sx={{ fontSize: "12px", color: grey["500"] }} />
              </AvatarStyled>

              <AvatarStyled
                onClick={() => deletePeople()}
                $backgroundColor={"transparent"}
                $borderColor={red["500"]}
              >
                <CloseIcon sx={{ fontSize: "12px", color: red["500"] }} />
              </AvatarStyled>
            </PopOverBoxStyled>
          )}

          {mode === "list" && (
            <PopOverBoxStyled>
              <AvatarStyled
                $backgroundColor={"transparent"}
                $borderColor={grey["500"]}
                $marginRight={10}
                onClick={() => dispatch({ type: "create" })}
              >
                <AddIcon sx={{ fontSize: "12px", color: grey["500"] }} />
              </AvatarStyled>

              <Box data-testid="people-avatar-list" sx={{ display: "flex" }}>
                {(people ?? []).map((initials, index) => {
                  return (
                    <AvatarStyled
                      key={index}
                      $backgroundColor={getInitialsColorFromName(initials)}
                      $marginRight={10}
                      onClick={() => {
                        dispatch({ type: "edit", original: initials });
                      }}
                    >
                      <Box sx={{ fontSize: "11px" }}>{initials}</Box>
                    </AvatarStyled>
                  );
                })}
              </Box>

              <AvatarStyled
                onClick={clearPeopleFromJob}
                $backgroundColor={"transparent"}
                $borderColor={red["500"]}
              >
                <CloseIcon sx={{ fontSize: "12px", color: red["500"] }} />
              </AvatarStyled>
            </PopOverBoxStyled>
          )}
        </form>
      </Popover>
    </>
  );
}

const colors = [
  blue[500],
  pink[500],
  red[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  lightBlue[500],
  cyan[500],
  teal[500],
  green[500],
  lightGreen[500],
  lime[800],
  yellow[800],
  amber[500],
  orange[500],
  deepOrange[500],
  brown[500],
  grey[500],
  blueGrey[500],
];

const PopOverBoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
`;

const BoxStyled = styled(Box)`
  display: inline-flex;
  margin-bottom: 2px;
  margin-right: 5px;
`;

interface AvatarStyledProps {
  $backgroundColor: string;
  $borderColor?: string;
  $marginRight?: number;
}

const AvatarStyled = styled(Avatar, { shouldForwardProp: shouldForwardProp })<AvatarStyledProps>`
  width: 24px;
  height: 24px;
  color: white;
  background-color: ${(props) => props.$backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.$borderColor ?? props.$backgroundColor};
  margin-right: ${(props) => props.$marginRight ?? 0}px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

type State =
  | { mode: "closed" }
  | { mode: "list"; anchorEl: HTMLDivElement }
  | { mode: "edit"; original: string; anchorEl: HTMLDivElement }
  | { mode: "create"; value: string; anchorEl: HTMLDivElement };

type Msg =
  | { type: "close" }
  | { type: "open"; anchorEl: HTMLDivElement }
  | { type: "edit"; original: string }
  | { type: "exitEdit" }
  | { type: "create" }
  | { type: "updateValue"; value: string };

const defaultState: State = { mode: "closed" };

function reducer(state: State, msg: Msg): State {
  if (msg.type === "close") {
    return defaultState;
  }

  switch (state.mode) {
    case "closed":
      switch (msg.type) {
        case "open":
          return { mode: "list", anchorEl: msg.anchorEl };
        default:
          return state;
      }
    case "list":
      switch (msg.type) {
        case "edit":
          return { ...state, mode: "edit", original: msg.original };
        case "create":
          return { ...state, mode: "create", value: "" };
        default:
          return state;
      }
    case "edit":
      switch (msg.type) {
        case "exitEdit":
          return { ...state, mode: "list" };
        default:
          return state;
      }
    case "create":
      switch (msg.type) {
        case "updateValue":
          return { ...state, value: msg.value };
        case "exitEdit":
          return { ...state, mode: "list" };
        default:
          return state;
      }
    default:
      return state;
  }
}

function getInitialsColorFromName(displayName: string | undefined): string {
  let color = colors[0];
  if (!displayName) {
    return color;
  }

  let hashCode = 0;
  for (let iLen: number = displayName.length - 1; iLen >= 0; iLen--) {
    const ch: number = displayName.charCodeAt(iLen);
    const shift: number = iLen % 8;
    // eslint-disable-next-line no-bitwise
    hashCode ^= (ch << shift) + (ch >> (8 - shift));
  }

  color = colors[hashCode % colors.length] ?? color;

  return color;
}
