import { createSvgIcon } from "@mui/material";

export const Unpinned = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 8.9898 2.6667 C 7.8523 2.6667 7.049 3.7812 7.4087 4.8604 L 8.4183 7.8891 C 7.095 8.3914 6.167 9.6705 6.167 11.1535 V 12.6667 C 6.167 13.1269 6.5401 13.5 7.0003 13.5 H 11.167 V 18.5 C 11.167 18.9602 11.5401 19.3333 12.0003 19.3333 C 12.4606 19.3333 12.8337 18.9602 12.8337 18.5 V 13.5 H 17.0003 C 17.4606 13.5 17.8337 13.1269 17.8337 12.6667 V 11.1535 C 17.8337 9.6705 16.9056 8.3914 15.5824 7.8891 L 16.5919 4.8604 C 16.9517 3.7812 16.1484 2.6667 15.0108 2.6667 H 8.9898 Z M 12.0003 11.8333 H 16.167 V 11.1535 C 16.167 10.1969 15.4283 9.4028 14.4742 9.3336 C 13.9147 9.2931 13.5432 8.7362 13.7206 8.204 L 15.0108 4.3333 H 8.9898 L 10.2801 8.204 C 10.4575 8.7362 10.086 9.2931 9.5264 9.3336 C 8.5724 9.4028 7.8337 10.1969 7.8337 11.1535 V 11.8333 H 12.0003 Z"
  />,
  "Unpinned",
);
