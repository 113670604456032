import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { useTagsById } from "src/hooks/useTags";
import { TagEntityType } from "src/services/models/Tag";
import { useEntityTagsCount } from "src/services/queries";

import { AddTagsModal } from "../AddTagModal";
import { EntityTagIem } from "../EntityTag";
import { ReviewJobTagsModal } from "./ReviewJobTagsModal";
import { CircularProgress } from "@mui/material";

interface JobTagListProps {
  jobId: string;
  tradespersonId?: string;
  /** default `false`. If true when default to having the add modal open should there be zero tags*/
  defaultAddModeOnZeroTags?: boolean;
}

export function JobTagList({
  jobId,
  tradespersonId,
  defaultAddModeOnZeroTags = false,
}: JobTagListProps): JSX.Element | null {
  const { tagsById } = useTagsById();

  const queryClient = useQueryClient();

  const entityTagsCountQuery = useEntityTagsCount({
    variables: { jobId },
    keepPreviousData: true,
  });

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

  const tagsGreaterThanZero = entityTagsCountQuery.data?.some((data) => data.count > 0) ?? false;
  const disableAddModeDefault = useRef(!defaultAddModeOnZeroTags);

  useEffect(() => {
    if (disableAddModeDefault.current) return;

    if (!tagsGreaterThanZero) {
      disableAddModeDefault.current = true;
      setIsAddModalOpen(true);
    }
  }, [tagsGreaterThanZero]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["jobEvents", { jobId }], {
      exact: false,
    });
    queryClient.invalidateQueries(["entityTagsCount", { jobId }], { exact: false });

    queryClient.invalidateQueries(["jobs"]);
  };

  const onReviewModalClosed = (reason: "close" | "mutate") => {
    if (reason === "mutate") {
      invalidateQueries();
    }

    setIsReviewModalOpen(false);
  };

  const onAddModalClosed = (reason: "close" | "mutate") => {
    if (reason === "mutate") {
      invalidateQueries();
    }
    setIsAddModalOpen(false);
  };

  const entityTagsCount = entityTagsCountQuery.data || [];
  if (Object.keys(tagsById).length === 0) {
    return null;
  }

  if (entityTagsCountQuery.isLoading) {
    return (
      <Paper>
        <CardContent>
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        </CardContent>
      </Paper>
    );
  }

  return (
    <Paper>
      <CardContent>
        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Tags</Typography>
          <Button
            data-testid="add-new-tags-btn"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setIsAddModalOpen(true)}
          >
            <AddIcon fontSize="small" sx={{ marginRight: "5px" }} />
            Add New
          </Button>
        </Stack>
      </CardContent>

      <Divider />

      <Container sx={{ paddingTop: "20px", paddingBottom: "10px" }}>
        <Stack data-testid="job-tag-list" direction="row" flexWrap={"wrap"} spacing={1}>
          {entityTagsCount.map((entityTagCount) => {
            return (
              <EntityTagIem
                key={`job-entity-tag-${entityTagCount.tagId}`}
                entityTagCount={entityTagCount}
                onCountClicked={() => {
                  setIsReviewModalOpen(true);
                }}
              />
            );
          })}
        </Stack>
      </Container>
      <AddTagsModal
        jobId={jobId}
        tradespersonId={tradespersonId}
        source={TagEntityType.Job}
        isOpen={isAddModalOpen}
        onClose={onAddModalClosed}
      />
      {isReviewModalOpen && (
        <ReviewJobTagsModal
          jobId={jobId}
          source={TagEntityType.Job}
          onClose={onReviewModalClosed}
        />
      )}
    </Paper>
  );
}
