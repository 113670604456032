import { createSvgIcon } from "@mui/material";

export const JobSheetIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 6 19.3333 L 16 19.3333 C 17.3807 19.3333 18.5 18.214 18.5 16.8333 V 11 L 18.5 7.9828 C 18.5 7.3717 18.2762 6.7818 17.8709 6.3246 L 16.4974 4.775 L 15.4286 3.5347 C 14.9536 2.9836 14.2622 2.6667 13.5347 2.6667 H 11 H 6 C 4.6193 2.6667 3.5 3.7859 3.5 5.1667 V 16.8333 C 3.5 18.214 4.6193 19.3333 6 19.3333 Z M 14.3333 4.8168 L 15.2424 5.8718 L 16.0947 6.8333 H 14.3333 V 4.8168 Z M 12.6667 4.3333 V 7.6667 C 12.6667 8.1269 13.0398 8.5 13.5 8.5 H 16.8333 V 11 L 16.8333 16.8333 C 16.8333 17.2936 16.4602 17.6667 16 17.6667 L 6 17.6667 C 5.5398 17.6667 5.1667 17.2936 5.1667 16.8333 L 5.1667 5.1667 C 5.1667 4.7064 5.5398 4.3333 6 4.3333 L 11 4.3333 L 12.6667 4.3333 Z M 7.6667 11 C 7.6667 10.5397 8.0398 10.1667 8.5 10.1667 H 13.5 C 13.9602 10.1667 14.3333 10.5397 14.3333 11 C 14.3333 11.4602 13.9602 11.8333 13.5 11.8333 H 8.5 C 8.0398 11.8333 7.6667 11.4602 7.6667 11 Z M 8.5 13.5 C 8.0398 13.5 7.6667 13.8731 7.6667 14.3333 C 7.6667 14.7936 8.0398 15.1667 8.5 15.1667 H 13.5 C 13.9602 15.1667 14.3333 14.7936 14.3333 14.3333 C 14.3333 13.8731 13.9602 13.5 13.5 13.5 H 8.5 Z"
  />,
  "JobSheet",
);
