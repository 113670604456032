import { createSvgIcon } from "@mui/material";

export const Pinned = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 7.9894 4 C 6.8518 4 6.0485 5.1145 6.4082 6.1937 L 7.4178 9.2224 C 6.0945 9.7247 5.1665 11.0038 5.1665 12.4869 V 14 C 5.1665 14.4602 5.5396 14.8333 5.9998 14.8333 H 10.1665 V 15.6667 C 10.1665 16.1269 10.5396 16.5 10.9998 16.5 C 11.4601 16.5 11.8332 16.1269 11.8332 15.6667 V 14.8333 H 15.9998 C 16.4601 14.8333 16.8332 14.4602 16.8332 14 V 12.4869 C 16.8332 11.0038 15.9052 9.7247 14.5819 9.2224 L 15.5915 6.1937 C 15.9512 5.1145 15.1479 4 14.0103 4 H 7.9894 Z M 10.9998 13.1667 H 15.1665 V 12.4869 C 15.1665 11.5303 14.4278 10.7361 13.4737 10.667 C 12.9142 10.6264 12.5427 10.0696 12.7201 9.5374 L 14.0103 5.6667 H 7.9894 L 9.2796 9.5374 C 9.457 10.0696 9.0855 10.6264 8.526 10.667 C 7.5719 10.7361 6.8332 11.5303 6.8332 12.4869 V 13.1667 H 10.98 Z"
  />,
  "Pinned",
);
