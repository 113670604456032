import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { addDays, endOfDay, format, formatISO, parseISO, startOfDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import type { RangeKeyDict } from "react-date-range";
import { DateRange } from "react-date-range";

import { shouldForwardProp } from "src/utils/shouldForwardProp";

import type { DateRangeFilterType } from "../job/JobListFilter/useJobFilters";

interface DateRangeFilterProps {
  dateRange: DateRangeFilterType;
  updateDateRange: (dateRange: DateRangeFilterType) => void;
}

export const DateRangeFilter = ({ dateRange, updateDateRange }: DateRangeFilterProps) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isCustomOpen, setIsCustomOpen] = useState(false);

  useEffect(() => {
    if (!dateRange.endDate && !dateRange.startDate && isCustomOpen) {
      setIsCustomOpen(false);
    }
  }, [dateRange.endDate, dateRange.startDate, isCustomOpen]);

  const label = useMemo((): "Custom" | "Today" | "Tomorrow" | "Anytime" => {
    if (isCustomOpen) {
      return "Custom";
    }

    const currentDate = startOfDay(new Date());
    const tomorrowDate = addDays(startOfDay(new Date()), 1);
    const currentDateFormated = format(currentDate, "yyyy-MM-dd");
    const tomorrowDateFormated = format(tomorrowDate, "yyyy-MM-dd");

    if (!dateRange.startDate && !dateRange.endDate) {
      return "Anytime";
    }

    const startDate = parseISO(dateRange.startDate ?? "");
    const startDateFormatted = format(startDate, "yyyy-MM-dd");

    const endDate = parseISO(dateRange.endDate ?? "");
    const endDateFormatted = format(endDate, "yyyy-MM-dd");

    if (currentDateFormated === startDateFormatted && currentDateFormated === endDateFormatted) {
      return "Today";
    }

    if (tomorrowDateFormated === startDateFormatted && tomorrowDateFormated === endDateFormatted) {
      return "Tomorrow";
    }

    return "Custom";
  }, [dateRange.endDate, dateRange.startDate, isCustomOpen]);

  const openDateRangePopover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onAnytimeClicked = () => {
    setIsCustomOpen(false);
    updateDateRange({});
  };

  const onTodayClicked = () => {
    const startDate = formatISO(startOfDay(new Date()));
    const endDate = formatISO(endOfDay(new Date()));

    setIsCustomOpen(false);
    updateDateRange({ startDate, endDate });
  };

  const onTomorrowClicked = () => {
    const startDate = formatISO(startOfDay(addDays(new Date(), 1)));
    const endDate = formatISO(endOfDay(addDays(new Date(), 1)));

    setIsCustomOpen(false);
    updateDateRange({ startDate, endDate });
  };

  const onCustomClicked = () => {
    if (isCustomOpen) {
      return;
    }
    const startDate = formatISO(startOfDay(new Date()));
    const endDate = formatISO(endOfDay(addDays(new Date(), 7)));

    setIsCustomOpen(true);
    updateDateRange({ startDate, endDate });
  };

  const onCustomDateRangeSelected = (ranges: RangeKeyDict) => {
    const selection = ranges["selection"];
    const startDate = selection.startDate;
    const endDate = selection.endDate;

    updateDateRange({
      startDate: formatISO(startOfDay(startDate ?? 0)),
      endDate: formatISO(endOfDay(endDate ?? 0)),
    });
  };

  const formatDate = () => {
    if (!dateRange.startDate && !dateRange.endDate) {
      return "-";
    }
    return `${format(parseISO(dateRange.startDate ?? ""), "dd MMM")} - ${format(
      parseISO(dateRange.endDate ?? ""),
      "dd MMM",
    )}`;
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  return (
    <div>
      <BoxStyled data-testid="date-range-filter-content-preview" onClick={openDateRangePopover}>
        <Typography color="blue" variant="button" data-testid="date-range-filter-label">
          {label === "Custom" ? formatDate() : label}
        </Typography>

        <CalendarMonthIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.dark, marginLeft: "auto" }}
        />
      </BoxStyled>
      <Popover
        data-testid="date-range-filter-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={(e) => {
          e.preventDefault();
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: { borderRadius: "12px", boxShadow: "0px 4px 24px #C7CDDB" },
        }}
      >
        <PopOverBoxStyled>
          <PopoverRow
            data-testid="date-range-filter-popover-option-anytime"
            onClick={onAnytimeClicked}
            color={label === "Anytime" ? theme.palette.primary.dark : theme.palette.grey[700]}
            variant="button"
          >
            Anytime
          </PopoverRow>

          <PopoverRow
            data-testid="date-range-filter-popover-option-today"
            onClick={onTodayClicked}
            color={label === "Today" ? theme.palette.primary.dark : theme.palette.grey[700]}
            variant="button"
          >
            Today
          </PopoverRow>

          <PopoverRow
            data-testid="date-range-filter-popover-option-tomorrow"
            onClick={onTomorrowClicked}
            color={label === "Tomorrow" ? theme.palette.primary.dark : theme.palette.grey[700]}
            variant="button"
          >
            Tomorrow
          </PopoverRow>

          <PopoverRow
            data-testid="date-range-filter-popover-option-custom"
            onClick={onCustomClicked}
            color={label === "Custom" ? theme.palette.primary.dark : theme.palette.grey[700]}
            variant="button"
          >
            Custom
          </PopoverRow>

          {label === "Custom" && (
            <DateRange
              ranges={[
                {
                  startDate: parseISO(dateRange.startDate ?? ""),
                  endDate: parseISO(dateRange.endDate ?? ""),
                  key: "selection",
                },
              ]}
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              onChange={onCustomDateRangeSelected}
              color={theme.palette.primary.dark}
              rangeColors={[theme.palette.primary.dark, theme.palette.primary.dark]}
            />
          )}
        </PopOverBoxStyled>
      </Popover>
    </div>
  );
};

const BoxStyled = styled(Box, { shouldForwardProp: shouldForwardProp })`
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  height: 44px;
  border-radius: 4px;
  min-width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PopOverBoxStyled = styled(Box)`
  padding: 15px;
  background-color: white;
  width: 360px;
`;

const PopoverRow = styled(Typography, { shouldForwardProp: shouldForwardProp })<{
  $color?: string;
}>`
  display: block;
  padding: 5px;
  padding-left: 10px;
  border: 1px solid #dae0ed;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke;
  }
`;
