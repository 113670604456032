import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { capitalCase } from "change-case";
import React, { useState } from "react";
import { useQueryClient } from "react-query";

import { useAddJobNote } from "src/services/mutations";
import { useJobEvents } from "src/services/queries";

import { DateAndTimeLabel } from "../DateAndTimeLabel";
import { TradespersonByPartnerTradesperson } from "../TradespersonByPartnerTradesperson";

interface JobNoteModalProps {
  jobId: string;
  partnerId: string;
  isOpen: boolean;
  /**
   * Close handler with a reason for closing.
   */
  onClose: (reason: "close" | "mutate") => void;
  /**
   * Show the three most recent notes associated with the job
   */
  showRecentNotes: boolean;
}

export function JobNoteModal(props: JobNoteModalProps) {
  const [jobNote, setJobNote] = useState("");
  const [shouldAddNoteToTp, setShouldAddNoteToTp] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);

  const { data, queryKey } = useJobEvents({
    skip: !props.showRecentNotes,
    variables: { jobId: props.jobId, pageSize: 3, eventType: "note_added" },
    keepPreviousData: true,
  });

  const queryClient = useQueryClient();
  const addJobNote = useAddJobNote({
    onSuccess: () => {
      // invalidating all event queries of the same job id
      queryClient.invalidateQueries([queryKey[0], { jobId: props.jobId }], {
        exact: false,
      });
    },
  });

  const handleCreateNote = async () => {
    try {
      await addJobNote.mutateAsync(
        {
          jobId: props.jobId,
          note: jobNote,
          shouldAddNoteToTp,
        },
        {},
      );
      setSnackBarMessage("Note Added Successfully");
      setJobNote("");
      setShouldAddNoteToTp(false);
    } catch (e) {
      // TODO: Add Error handling
    }
    props.onClose("mutate");
  };

  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <ModalContainer>
          <ModalHeader>
            <Stack gap="5px" justifyContent="space-between" direction="row" flexWrap="nowrap">
              <Typography noWrap variant="h5">
                Add Job Note
              </Typography>

              <IconButton aria-label="close" onClick={() => props.onClose("close")}>
                <Close />
              </IconButton>
            </Stack>
          </ModalHeader>
          <TextField
            required
            fullWidth
            multiline
            rows={4}
            name="note"
            placeholder="Type a message"
            variant="outlined"
            onChange={(e) => setJobNote(e.target.value)}
            value={jobNote}
          />
          <FormContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldAddNoteToTp}
                  onChange={(e) => {
                    setShouldAddNoteToTp(e.target.checked);
                  }}
                />
              }
              label="Add to TP Profile"
              labelPlacement="end"
            />
            <Button variant="contained" color="primary" onClick={handleCreateNote}>
              Submit Note
            </Button>
          </FormContainer>
          {props.showRecentNotes && (
            <>
              <Divider></Divider>

              <Typography noWrap variant="h6">
                Most Recent Notes
              </Typography>

              <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Actor </TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.results?.map((jobEvent) => {
                      return (
                        <TableRow key={jobEvent.id}>
                          <TableCell width="20%">
                            {jobEvent.createdAt && (
                              <DateAndTimeLabel dateAndTime={jobEvent.createdAt} />
                            )}
                          </TableCell>
                          <TableCell width="20%">
                            {jobEvent.actor === "tradesperson" && jobEvent.actorInstanceUuid ? (
                              <TradespersonByPartnerTradesperson
                                partnerId={props.partnerId}
                                tradespersonId={jobEvent.actorInstanceUuid}
                              />
                            ) : (
                              <Typography variant="body2" color="neutral.500">
                                <Typography noWrap={true} variant="caption" component="span">
                                  {jobEvent.actor && capitalCase(jobEvent.actor)}
                                </Typography>
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell width="50%">
                            {jobEvent.eventType === "note_added" && (
                              <Typography>{jobEvent.context.note}</Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </ModalContainer>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(snackBarMessage)}
        onClose={() => {
          setSnackBarMessage(null);
        }}
        message={snackBarMessage}
        autoHideDuration={3000}
        ContentProps={{ sx: { backgroundColor: "success.main" } }}
      />
    </>
  );
}

const ModalContainer = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40%;
  max-width: 48em;
  min-height: 200px;

  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-flow: column;
  align-items: stretch;

  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  background-color: white;
  border-radius: 20px;
`;

const FormContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 10px;
`;

const ModalHeader = styled("div")`
  width: 100%;
`;
