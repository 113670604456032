import { Menu, MenuItem, IconButton } from "@mui/material";
import type { MouseEvent } from "react";
import { useState } from "react";

import { JobSheetIcon } from "src/icons/job-sheet";
import { SingleDoc } from "src/icons/single-doc";
import { useJobEvents } from "src/services/queries";

interface JobListActionButtonProps {
  jobId: string;
  onOpenNotes: () => void;
  onOpenTags: () => void;
}

export function JobListActionButton(props: JobListActionButtonProps) {
  const { data } = useJobEvents({
    variables: { jobId: props.jobId, eventType: "note_added" },
    keepPreviousData: true,
  });

  const hasNotes = typeof data?.count === "number" && data.count > 0;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleOpenNote = (e: MouseEvent) => {
    handleClose(e);
    props.onOpenNotes();
  };

  const handleOpenTags = (e: MouseEvent) => {
    handleClose(e);
    props.onOpenTags();
  };

  return (
    <>
      <IconButton
        data-testid="job-menu-button"
        color="primary"
        type="button"
        aria-label="Notes"
        onClick={handleClick}
      >
        {hasNotes ? <JobSheetIcon /> : <SingleDoc />}
      </IconButton>

      <Menu
        id="jobListActionMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "job menu",
        }}
      >
        <MenuItem data-testid="job-note-button" onClick={handleOpenNote}>
          Add Job Note
        </MenuItem>
        <MenuItem onClick={handleOpenTags}>Add Tag</MenuItem>
      </Menu>
    </>
  );
}
