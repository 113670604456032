import CardActionArea from "@mui/material/CardActionArea";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

import { shouldForwardProp } from "src/utils/shouldForwardProp";

interface QuickFilterCardProps {
  header: string | number;
  subheader?: string;
  onClick: () => void;
  isSelected: boolean;
}

export const QuickFilterCard = ({
  header,
  subheader,
  onClick,
  isSelected,
}: QuickFilterCardProps) => (
  <FilterButton
    $selected={isSelected}
    onClick={() => {
      onClick();
    }}
  >
    <Typography color="textPrimary" variant="h4">
      {header}
    </Typography>
    <Typography color="textSecondary" variant="subtitle2">
      {subheader}
    </Typography>
  </FilterButton>
);

interface FilterButtonProps {
  $selected: boolean;
}

const FilterButton = styled(CardActionArea, {
  shouldForwardProp: shouldForwardProp,
})<FilterButtonProps>`
  border-left: 2px solid;
  border-radius: 4px;
  padding: 16px;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.grey[200] : theme.palette.common.white};

  border-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.primary.dark : "transparent"};
`;
