import { useMemo } from "react";

import { useAuthStore } from "src/context/authStore";
import type { Partner } from "src/services/models/Partner";
import { usePartners } from "src/services/queries";

export const MINUTE_IN_MILLIS = 60 * 1000;

export const usePartnersById = () => {
  const partnersQuery = usePartners({
    variables: {},
    keepPreviousData: true,
    staleTime: 15 * MINUTE_IN_MILLIS,
  });

  const { partnerId, isAdmin } = useAuthStore();

  const partnersById = useMemo(() => {
    const map: Record<string, Partner> = {};

    (partnersQuery.data || []).forEach((partner: Partner) => {
      if (isAdmin) {
        map[partner.id] = partner;
      } else {
        if (partnerId === partner.id) {
          map[partner.id] = partner;
        }
      }
    });

    return map;
  }, [partnersQuery.data, isAdmin, partnerId]);

  const isKantan = isKantanPartner(partnersById[partnerId ?? ""]?.slug);
  const isOvoOD = isOvoODPartner(partnersById[partnerId ?? ""]?.slug);
  const isOvoFreeService = isOvoFreeServicePartner(partnersById[partnerId ?? ""]?.slug);
  const isFCG = isFCGPartner(partnersById[partnerId ?? ""]?.slug);

  return {
    partnersById,
    isKantan,
    isOvoOD,
    isOvoFreeService,
    isFCG,
    currentPartnerId: partnerId,
    currentPartnerSlug: partnersById[partnerId ?? ""]?.slug,
  };
};

export const isKantanPartner = (slug: string) => slug === "kantan";
export const isOvoODPartner = (slug: string) => slug === "ovo-energy";
export const isOvoFreeServicePartner = (slug: string) => slug === "ovo-energy-free-services";
export const isFCGPartner = (slug: string) => slug === "first-call";
