import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";

import { useTradespersonPartner } from "src/services/queries";
import { getShortReferenceId } from "src/utils/jobs";
import type { Maybe } from "src/utils/types";

interface TradespersonLabelProps {
  referenceId: Maybe<string>;
}

export const TradepsersonLabel = ({ referenceId }: TradespersonLabelProps) => {
  const tradespersonQuery = useTradespersonPartner({
    variables: { id: referenceId as string },
    skip: !referenceId,
  });
  const tradesperson = tradespersonQuery.data;

  if (tradesperson?.tradesperson) {
    return (
      <Link
        href={{ pathname: "/tradespeople/[id]", query: { id: tradesperson.referenceId } }}
        passHref
        legacyBehavior
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" fontWeight="500" color="#0B4ED5">
              {tradesperson.tradesperson.companyName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="#478EFF">
              {tradesperson.tradesperson.fullName}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    );
  } else if (referenceId) {
    return <>{getShortReferenceId(referenceId)}</>;
  } else {
    return <>Unallocated</>;
  }
};
