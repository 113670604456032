import { Add } from "@mui/icons-material";
import type { IconButtonProps } from "@mui/material/IconButton";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import type { Ref } from "react";
import { forwardRef } from "react";

const Button = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  height: 44px;
  width: 44px;

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const AddButton = forwardRef(function AddButton(
  props: IconButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  return (
    <Button {...props} ref={ref}>
      <Add />
    </Button>
  );
});
