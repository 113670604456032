import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { format, parseISO } from "date-fns";

import { utcToZonedLondon } from "src/utils/dates";
import type { Maybe } from "src/utils/types";

interface DateAndTimeProps {
  dateAndTime: Maybe<string>;
}

export const DateAndTimeLabel = ({ dateAndTime }: DateAndTimeProps) => {
  if (!dateAndTime) {
    return null;
  }
  const date = utcToZonedLondon(parseISO(dateAndTime));

  if (!date) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="column" alignItems="baseline" gap={"2px"}>
        <Typography noWrap={true} variant="caption" component="span">
          {format(date, "eee")}, {format(date, "HH:mm")}
        </Typography>
        <Typography noWrap={true} component="span" variant="caption" fontWeight="600">
          {format(date, "d LLL ’yy")}
        </Typography>
      </Stack>
    </Stack>
  );
};
